.forgot-password-page-container {
    width: 700px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.forgot-password-info-label {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.forgot-password-input-container {
    text-align: center;
    margin-bottom: 30px;
}

.forgot-password-input-container .p-inputtext {
    width: 400px;
}

.forgot-password-button {
    display: flex;
    justify-content: center;
}

.forgot-password-feedback-message {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #d63c52;
}

.forgot-password-spinning-loader {
    margin: 10px;
    text-align: center;
}

.user-update-password {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-update-password-button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.forgot-password-page-container .error {
    color: #d63c52;
    margin-top: 2px;
}

.user-password-reset-success {
    color: #d63c52;
    text-align: center;
}