.admin-page-container {
    width: 900px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.admin-users-page-container {
    width: 1000px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.admin-welcome-label {
    text-align: center;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
}

.admin-file-limit-config {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.admin-file-limit-config .p-inputtext {
    width: 100px;
}

.admin-file-limit-config .p-dropdown {
    width: 100px;
}

.save-admin-settings-button {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #00000026;
}

.admin-users-button {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.admin-users-label {
    text-align: center;
    margin-bottom: 20px;
}

.admin-users-table {
    margin-left: 20px;
    margin-right: 20px;
    max-height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.p-datatable .p-datatable-tbody > tr > td {
    word-wrap: break-word;
}

.admin-user-info-label {
    text-align: center;
    margin-bottom: 20px;
}

.admin-user-files-page-container {
    width: 700px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.admin-user-files-container {
    margin: 25px 16px;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.admin-update-user-page-container {
    width: 700px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.admin-update-password {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.admin-update-password-button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


.admin-update-user-page-container .error {
    color: #d63c52;
    margin-top: 2px;
}

.admin-password-reset-success {
    color: #d63c52;
    text-align: center;
}

.admin-confirm-user-label {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.admin-activate-account-success {
    color: #d63c52;
    text-align: center;
}

.admin-settings-success {
    color: #d63c52;
    text-align: center;
}

.admin-add-user {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 100px;
}

.admin-add-society {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid #00000026;
}

.admin-add-society-input-label {
    line-height: 42px;
    font-weight: 500;
}

.admin-add-society .p-inputtext {
    width: 300px;
}

.admin-societies-list {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-wrap: wrap;
}

.admin-society-item {
    margin: 16px 30px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 3px;
}

.admin-society-item-content {
    display: flex;
    margin: 10px;
}

.admin-society-item .p-button.p-button-icon-only.p-button-rounded {
    height: 30px !important;
    width: 30px !important;
}

.admin-society-item-name {
    line-height: 30px;
    margin-right: 15px;
    margin-left: 5px;
    font-weight: 500;
}

.button-download-file {
    padding-top: 16px;
}

.admin-update-society {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #00000026;
    padding-top: 10px;
    margin-bottom: -20px;
}

.admin-update-society-label {
    line-height: 92px;
}

.admin-update-society-dropdown {
    width: 220px;
}

.admin-update-society-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-update-society-feedback {
    color: #d63c52;
    text-align: center;
}