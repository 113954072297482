.home-page-ibb-logo-container {
    display: flex;
    justify-content: center;
}

.ibb-logo {
    width: 400px;
}

@media(max-width: 600px) {
    .ibb-logo {
        width: 300px;
    }
}

.home-page-selection-buttons {
    margin-top: 50px;
}

.home-page-upload-button {
    margin-bottom: 25px;
}

.home-page-upload-button, .home-page-download-button {
    text-align: center;
}

.home-page-selection-buttons .p-button {
    font-size: 18px !important;
}