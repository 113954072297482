.header {
    padding-top: 15px;
    padding-right: 15px;
    z-index: 10;
    position: absolute;
    right: 0;
}

.dropdown-menu {
    min-width: 164px !important;
    border-radius: 7px !important;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu:focus {
    outline: transparent !important;
}

.header-dropdown-toggle {
    color: #0075ff !important;
}

.header-dropdown-toggle-label {
    font-weight: bold;
    line-height: 35px;
    margin-left: 10px;
}

.dropdown-menu-arrow:before {
    background: #ffffff;
    box-shadow: none;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    right: 25px;
    left: auto;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(12px);
    z-index: -5;
}

.dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    transition: opacity .2s;
}