.upload-page-container {
    width: 520px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

@media(max-width: 520px) {
    .p-fileupload .p-fileupload-buttonbar .p-button {
        margin-bottom: 8px;
    }

    .upload-page-container {
        width: 320px !important;
    }
}

.upload-container {
    width: 520px;
}

.p-fileupload-content {
    max-height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.p-fileupload .p-fileupload-row > div {
    padding: 16px 12px !important;
}

.expiration-label {
    color: #000000;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.expiration-dropdown {
    margin-bottom: 20px;
}

.expiration-dropdown .p-dropdown.p-component {
    margin: auto;
}

.upload-feedback {
    color: #000000;
    padding-left: 16px;
    padding-right: 16px;
}

.upload-url {
    word-wrap: break-word;
}

.p-fileupload-row div {
    text-overflow: ellipsis;
    overflow: hidden;
}

.upload-limit-label {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #00000026;
}

.upload-in-progress {
    margin: 10px;
    text-align: center;
}

.files-up {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.upload-select-buttons {
    margin: 30px;
    display: flex;
    justify-content: space-around;
}

.upload-file-list {
    background: #ffffff;
    padding: 32px 16px 6px 16px;
    border: 1px solid #dee2e6;
    color: #495057;
    max-height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.upload-limit-error {
    background: #d63c525c;
    display: flex;
    justify-content: space-around;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dee2e6;
}

.upload-limit-error-label {
    line-height: 37px;
    padding-left: 40px;
}