.user-page-container {
    width: 900px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.user-upload-label {
    text-align: center;
    margin-top: 25px;
    font-weight: 500;
}

.user-download-container {
    margin: 25px 16px;
}

.user-download-file-item {
    text-align: center;
    margin-bottom: 10px;
    word-wrap: break-word;
    padding-top: 15px;
    padding-bottom: 5px;
    position: relative;
}

.user-preview-file {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 80px;
    margin: auto;
    overflow: hidden;
}

.file-url-container {
    margin: auto;
    width: 360px;
}

.user-copy-file {
    position: absolute;
    top: 10px;
    right: 60px;
}
.user-copy-file .p-button.p-button-secondary {
    box-shadow: none !important;
}

.user-download-file {
    position: absolute;
    top: 10px;
    right: 100px;
}

.user-download-file .p-button.p-button-secondary {
    box-shadow: none !important;
}

.user-delete-file {
    position: absolute;
    top: 10px;
    right: 10px;
}

.upload-page-button {
    text-align: center;
    padding-top: 20px;
    margin-bottom: -10px;
}

.user-secondary-info-label {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

.user-link-container {
    margin: 8px 0;
}

.user-links-table {
    margin-left: 20px;
    margin-right: 20px;
}

.user-links-page-container {
    width: 900px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.user-link-save-button {
    margin: 8px 12px;
    text-align: center;
}

.p-calendar .p-datepicker {
    min-width: 360px !important;
}

.button-download-file {
    padding-top: 16px;
}