body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-background {
    min-height: 100vh;
    width: 100%;
    background-position: 50%;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-background:before {
    background: rgba(0,0,0,.2);
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 500px;
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
}

.page-container {
    width: 450px;
    margin: auto;
    color: #ffffff;
    z-index: 1;
}

.bold {
    font-weight: bold;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

/* conflict bootstrap + material ui buttons */
button:focus {
    outline: 0 !important;
}

.bg-blue {
    background-color: #0075ff !important;
}

.home-page-button {
    text-align: center;
    padding-top: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: 1px solid #00000026;
}