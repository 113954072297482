.download-page-container {
    width: 700px !important;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    margin: auto;
    z-index: 1;
}

.download-container {
    margin: 25px 16px;
}

.download-file-item {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
}
.download-file-item {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
    border-top: 1px solid #00000026;
    padding-top: 15px;
    padding-bottom: 5px;
    position: relative;
}

.preview-file {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 80px;
    margin: auto;
    overflow: hidden;
}

.copy-file {
    position: absolute;
    top: 10px;
    right: 10px;
}
.copy-file .p-button.p-button-secondary {
    box-shadow: none !important;
}

.download-file {
    position: absolute;
    top: 10px;
    right: 60px;
}

.download-file .p-button.p-button-secondary {
    box-shadow: none !important;
}

.button-download-file {
    padding-top: 16px;
}