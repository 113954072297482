@media (min-width: 960px) and (max-width: 991px) {
    .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 66.666667% !important;
        flex-basis: 66.666667% !important;
    }
}

@media (min-width: 576px) {
    .login-wrapper {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .login-wrapper {
        max-width: 720px;
    }
}

@media (min-width: 991px) {
    .login-wrapper {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .login-wrapper {
        max-width: 1140px;
    }
}

.login-wrapper {
    width: 100%;
    z-index: 4;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.login-grid-container {
    width: auto !important;
    margin-left: -15px;
    margin-right: -15px;
}

.login-grid-item {
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.login-form-wrapper {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-direction: column;
}

.login-header {
    width: auto;
    text-align: center;
    color: #ffffff;
    background-color: #0075ff;
    box-shadow: 0 12px 20px -10px rgba(0, 117, 255, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 117, 255, 0.2);
    padding: 20px 0;
    margin: -30px 20px 15px;
    border-radius: 3px;
    border: 0;
}

.header-login-label {
    margin-top: .625rem;
    min-height: auto;
    font-weight: 700;
    margin-bottom: 0.75rem;
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 1.5em;
}

.login-body {
    padding: 0 30px 20px 30px;
}

.login-form-control {
    margin: 0 0 17px 0 !important;
    padding-top: 27px !important;
}

.login-form-control.name-error, .login-form-control.email-error, .login-form-control.password-error {
    margin: 0 !important;
}

.login-form-input:before {
    border-bottom-color: #D2D2D2 !important;
    border-bottom-width: 1px !important;
}

.login-form-input:after {
    border-bottom-color: #0075ff !important;
}

.login-submit-buttons-container {
    text-align: center;
    margin-bottom: 35px;
    align-items: center;
}

.auth-button {
    font-weight: bold;
    background-color: #0075ff !important;
    color: #ffffff !important;
}

.auth-modal ~ .modal-backdrop.fade {
    opacity: 0 !important;
}

.auth-modal.show .modal-dialog {
    transform: translate(0, 100%) !important;
}

.auth-modal .modal-content {
    color: #ffffff;
    border: 0 !important;
}

.auth-modal.login-modal .modal-dialog {
    max-width: 255px;
    margin: 0 auto;
}

.auth-modal.register-modal .modal-dialog {
    max-width: 270px;
    margin: 0 auto;
}

.auth-modal.fade .modal-dialog {
    transition: transform .3s ease-out;
}

.auth-modal-label {
    padding: 16px;
    border-radius: .3rem;
}

.user-already-logged-in {
    text-align: center;
    margin: 20px;
}

.login-body .error {
    color: #d63c52;
    margin-top: 2px;
}

.login-body .login-form-control label {
    margin-bottom: 0 !important;
}

.login-body .error {
    color: #d63c52;
    margin-top: 2px;
}

.captcha-error {
    color: #d63c52;
    margin-top: 2px;
}

.MuiFormLabel-root.Mui-focused {
    color: #0075ff !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #0075ff !important;
}

.MuiInputLabel-shrink {
    transform: translate(0, 20px) scale(0.75) !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
     border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}